"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Deployment = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Deployment extends Base_1.Base {
    constructor(data, olMap) {
        super('deployments', 10);
        this.name = '';
        this.changeId = '';
        this.notes = '';
        this.created = null;
        this.approved = 0;
        this.approved_by = '';
        this.deployed = null;
        this.running = false;
        this.status = '';
        this.pages = {};
        this.allPages = true;
        this.feedbackId = '';
        this.agents = [];
        this.followers = [];
        this.roles = [];
        this._m = ['name'];
        (0, Common_1.setObjectProperties)(this, data, olMap, Deployment);
    }
    toString() {
        return `Deployment: ${this.name}`;
    }
    async save(parent) {
        if (!this.id) {
            this.setID();
        }
        if (parent && parent._docRef) {
            this._docRef = parent._docRef.collection('cms').doc('root').collection(this._type).doc(this.id);
        }
        if (!this._docRef) {
            throw `Cannot save! No document reference.`;
        }
        else {
            if (!this.created) {
                this.created = Date.now();
            }
            return super.save();
        }
    }
}
exports.Deployment = Deployment;
index_1.olm.deployments = (ref, map) => {
    return new Deployment(ref, map);
};
