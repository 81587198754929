"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextThread = exports.Thread = exports.DEFAULT_SUBJECT = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const Contact_1 = require("./Contact");
const index_1 = require("../index");
exports.DEFAULT_SUBJECT = 'New Message/Thread';
class Thread extends Base_1.Base {
    get unassigned() {
        var _a, _b, _c;
        return !!(!((_a = this.agents) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.followers) === null || _b === void 0 ? void 0 : _b.length) && !((_c = this.roles) === null || _c === void 0 ? void 0 : _c.length));
    }
    get name() {
        var _a;
        return ((_a = this.object) === null || _a === void 0 ? void 0 : _a.toString()) || '';
    }
    constructor(data, olMap, type, idLen) {
        var _a;
        super(type || 'threads', idLen || 24);
        this.third_party = '';
        this.gmail_id = '';
        this.subject = '';
        this.summary = '';
        this.inbound = false;
        this.html = ''; // deprecate/move to msgs
        this.type = '';
        this.medium = index_1.EMedium.NONE;
        this.template_id = ''; // deprecate/move to msgs
        this.url = '';
        this.creator = {};
        this.contact = null;
        this.object = null;
        this.recipients = [];
        this.filters = [];
        this.activity = [];
        this.follower_ids = [];
        this.ref = null;
        this.contactRef = null;
        this.agents = [];
        this.followers = [];
        this.roles = [];
        this._m = ['date', 'creator', 'medium', 'subject', 'summary'];
        this._s = ['name', 'filters', 'activity', 'medium', 'unread', 'inbound', 'active', 'cc', 'object', 'html', 'recipients', 'contact', 'creator', 'subject', 'summary', 'unassigned'];
        (0, Common_1.setObjectProperties)(this, data, olMap, Thread);
        if (this.object) {
            this.object = (0, index_1.loadObject)(this.object, { olm: olMap });
            if (this.object && ((_a = this.contact) === null || _a === void 0 ? void 0 : _a._docRef)) {
                this.contact = (0, index_1.loadObject)(this.contact, { olm: olMap });
                if (this.object && this.contact && !(this.object instanceof Contact_1.Contact)) {
                    this.object.contact = this.contact;
                }
            }
        }
    }
    toString() {
        return `Thread: ${this.medium}: ${this.subject || this.summary}`;
    }
    async markRead() {
        if (this.id && this._unread) {
            this._unread.seen = true;
            this._unread.last_date = Date.now();
            if (this._unread._exists) {
                await this._unread.save();
            }
        }
    }
    async loadAll(opts, stack, p) {
        var _a, _b, _c, _d, _e, _f;
        await super.loadAll(opts, stack, p);
        if (((_a = this.ref) === null || _a === void 0 ? void 0 : _a.get) && !((_b = this.object) === null || _b === void 0 ? void 0 : _b._docRef) && opts.olm) {
            try {
                this.object = (0, index_1.loadObject)((await this.ref.get()), { olm: opts.olm });
            }
            catch (e) {
                console.warn(e.toString(), (_c = this.ref) === null || _c === void 0 ? void 0 : _c.path);
            }
        }
        if (((_d = this.contactRef) === null || _d === void 0 ? void 0 : _d.get) && !((_e = this.contact) === null || _e === void 0 ? void 0 : _e._docRef) && opts.olm) {
            try {
                this.contact = (0, index_1.loadObject)((await this.contactRef.get()), { olm: opts.olm });
            }
            catch (e) {
                console.warn(e.toString(), (_f = this.contactRef) === null || _f === void 0 ? void 0 : _f.path);
            }
        }
    }
    async save(parent) {
        var _a, _b, _c, _d;
        if ((_a = this.object) === null || _a === void 0 ? void 0 : _a._docRef) {
            this.ref = (_b = this.object) === null || _b === void 0 ? void 0 : _b._docRef;
        }
        if ((_c = this.contact) === null || _c === void 0 ? void 0 : _c._docRef) {
            this.contactRef = this.contact._docRef;
        }
        if (!this.ref && !(this instanceof index_1.ThreadMessage)) {
            throw `Cannot Save! No reference set on ${(_d = this._type) === null || _d === void 0 ? void 0 : _d.replace(/s$/, '')}.`;
        }
        if (this.gmail_id) {
            this.third_party = this.gmail_id;
        }
        await super.save(parent);
    }
    toJSON() {
        var _a, _b, _c, _d, _e, _f;
        let r = super.toJSON();
        r.follower_ids = (((_a = r.followers) === null || _a === void 0 ? void 0 : _a.length) && ((_b = r.followers) === null || _b === void 0 ? void 0 : _b.map)) ? r.followers.map(f => f.id) : [];
        if (((_c = r.agents) === null || _c === void 0 ? void 0 : _c.length) && ((_d = r.agents) === null || _d === void 0 ? void 0 : _d.map)) {
            r.follower_ids = r.follower_ids.concat(r.agents.map(f => f.id));
        }
        r.filters = (0, index_1.toMinJSON)(this, 'filters', true);
        r.unassigned = this.unassigned;
        if ((_e = this.object) === null || _e === void 0 ? void 0 : _e._docRef) {
            r.ref = this.object._docRef;
        }
        if ((_f = this.contact) === null || _f === void 0 ? void 0 : _f._docRef) {
            r.contactRef = this.contact._docRef;
        }
        return r;
    }
}
exports.Thread = Thread;
index_1.olm.threads = (ref, map) => {
    return new Thread(ref, map);
};
class TextThread extends Base_1.Base {
    constructor(data, olMap) {
        super('_sms', null);
        this.threadRef = null;
        this.contactRef = null;
        this.contactPhone = '';
        this.twilioPhone = '';
        (0, Common_1.setObjectProperties)(this, data, olMap, TextThread);
    }
    async save(parent) {
        this.id = `${this.contactPhone}-${this.twilioPhone}`;
        this._docRef = parent.doc(`${this._type}/${this.id}`);
        return super.save();
    }
}
exports.TextThread = TextThread;
index_1.olm._sms = (ref, map) => {
    return new TextThread(ref, map);
};
